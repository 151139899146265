import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { HeadTags, Layout, Grid, BlockRenderer, FaqCategories, FrequentlyAskedQuestions } from '@components'

interface PageProps {
  data: Queries.FaqPageQuery
}

const FaqPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage || !sanityPage.qaSets) return <></>
  const { _rawCopy, qaSets } = sanityPage
  const categories = qaSets.map(item => item?.category!).filter((value, index, self) => !!value && self.indexOf(value) === index)
  const [activeCategory, setActiveCategory] = useState('all')

  return (
    <Layout>
      <Grid>
        <Headline>
          <BlockRenderer>{_rawCopy}</BlockRenderer>
        </Headline>
        <FaqCategories {...{ categories, activeCategory, setActiveCategory }} />
        <FrequentlyAskedQuestions {...{ qaSets, activeCategory }} />
      </Grid>
    </Layout>
  )
}

const Headline = styled.header`
  grid-column: 1 / span 6;
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 944px) {
    grid-column: 1 / span 12;
    min-height: 400px;
  }
`

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/faq" />
  </HeadTags>
)

export const query = graphql`
  query FaqPage {
    sanityPage(slug: { current: { eq: "faq" } }) {
      ...pageFields
      qaSets {
        question
        category
        _rawAnswer
      }
    }
  }
`

export default FaqPage
